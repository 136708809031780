import React, {useEffect, useState } from "react"
import { createGlobalState } from 'react-hooks-global-state';
import { client, shopify } from "./client";
import { Checkout } from "./models/checkout";
const { useGlobalState } = createGlobalState({
  checkoutId: '',
  itemCount: 0,
  checkout: null,
  initialized: false,
  vacation: null,
});

const STORAGE_KEY = 'cart';

export const useCart = () => {
    const [checkoutId, setCheckoutId] = useGlobalState('checkoutId');
    const [checkout, setCheckout] = useGlobalState('checkout');
    const [itemCount, setItemCount] = useGlobalState('itemCount');
    const [initialized, setInitialized] = useGlobalState('initialized');
    const [vacation, setVacation] = useState(null);

    const initializeCart = (cartId) => {
      return client.request(
        `
        mutation cartDeliveryAddressesAdd(
          $cartId: ID!, $addresses: [CartSelectableAddressInput!]!
        ) {
          cartDeliveryAddressesAdd(
            cartId: $cartId, addresses: $addresses
          ) {
            cart {
              id
              deliveryGroups(first: 10) {
                nodes {
                  id
                  deliveryOptions{
                    description
                    estimatedCost {
                      amount
                    }
                    handle
                  }
                }
              }
            }
            userErrors {
              message
            }
          } 
          cartDiscountCodesUpdate(cartId: $cartId, discountCodes: []) {
            cart {
              id
              cost {
                totalAmount {
                  amount
                }
              }
            }
            userErrors {
              message
            }
          }
        }                             
      `,
        {
          cartId,
          addresses: [{
            selected: true,
            oneTimeUse: true,
            address: {
              deliveryAddress: {
                address1: "合作咖啡",
                address2: "",
                city: "台中市",
                company: "",
                firstName: "",
                lastName: "合作咖啡",
                phone: "",
                countryCode: "TW",
                zip: "",
              }
            }
          }],
        },
      );
    }

    const fetchCheckout = async (id = '') => {
        if (!id && !checkoutId) return null;
        const data = await client.request(`
          query {
            vacation: products(first: 1, query: "tag:休假模式") { 
              edges { node { 
                handle title descriptionHtml
              }} 
            }
            node(id: "${id || checkoutId}") {
              ...on Cart {
                note
                delivery {
                  addresses {
                    id
                  }
                }
                cost {
                  totalAmount {
                    amount
                  }
                  subtotalAmount {
                    amount
                  }
                }
                deliveryGroups(first: 10) {
                  nodes {
                    id
                    deliveryOptions{
                      description
                      estimatedCost {
                        amount
                      }
                      handle
                      title
                    }
                  }
                }
                discountAllocations {
                  discountApplication {
                    allocationMethod
                    targetType
                    value {
                      ... on MoneyV2 {
                        amount
                      }
                    }
                  }
                  discountedAmount {
                      amount
                  }
                }
                lines(first: 100) {
                    edges {
                        node {
                            id
                            quantity
                            attributes {
                              key
                              value
                            }
                            cost {
                              amountPerQuantity {
                                amount
                              }
                              subtotalAmount {
                                amount
                              }
                            }
                            merchandise {
                              ...on ProductVariant {
                                id
                                title
                                image {
                                    url
                                }
                                priceV2 {
                                    amount
                                }
                                product {
                                    title
                                    handle
                                    productType
																		metafields(identifiers: [{ key: "eng_name", namespace: "custom"}]) {
																			id
																			value
																			key
                                    }
                                }
                              }
                            }
                        }
                    }
                }
              }
            }
          }
        `);
        if (!data?.node) {
            return createCheckout();
        }
        const disabledItems = data?.node?.lines?.edges?.filter(e => !e?.node?.merchandise);
        if (disabledItems.length > 0) {
          return createCheckout();
        }
        const checkout = Checkout.fromShopify(data);
        const vacation = data?.vacation?.edges?.[0]?.node?.descriptionHtml;
        if (vacation) {
          setVacation(vacation.replace(/<br>/g, '\n').replace(/<[^>]*>?/gm, ''));
        }

        if (checkout?.items?.length) {
            setItemCount(checkout?.items?.length);
        }
        setCheckout(checkout);
        return checkout;
    }

    const createCheckout = async () => {
        const checkout = await shopify.checkout.create();
        localStorage.setItem(STORAGE_KEY, checkout.id);
        await initializeCart(checkout.id);
        setCheckoutId(checkout.id);
        setCheckout(checkout);
        setInitialized(true);
        return checkout;
    }

    useEffect(() => {
        let localCheckoutId = localStorage.getItem(STORAGE_KEY);
        if (localCheckoutId) {
          setCheckoutId(localCheckoutId);
          fetchCheckout(localCheckoutId).then(() => {
            setInitialized(true);
          });
        } else {
          createCheckout().then(() => {
            setInitialized(true);
          });
        }
    }, [])



    const addItem = async (items) => {
        setItemCount(itemCount + 1)
        if (!checkoutId) {
            const checkout = await createCheckout();
            await shopify.checkout.addLineItems(checkout.id, items);
        } else {
            await shopify.checkout.addLineItems(checkoutId, items);
        }
        fetchCheckout();
    };

    const removeItem = async  (id) => {
        await shopify.checkout.removeLineItems(checkoutId, [id]);
        fetchCheckout();
    }

    const updateItems = async (items) => {
      await shopify.checkout.updateLineItems(checkoutId, items);
      fetchCheckout();
    }

    const clearCart = async () => {
      localStorage.removeItem(STORAGE_KEY);
      window.location.href = '/';
    }

    const updateCheckoutNote = async (note) => {
      await client.request(`
						mutation {
							cartNoteUpdate(
								cartId: "${checkoutId}"
                note: "${note}"
							) {
								cart {
									id
								}
							}
						}
        `);
      fetchCheckout();
    }

    return {
      addItem,
      checkoutId,
      itemCount,
      fetchCheckout,
      createCheckout,
      removeItem,
      checkout,
      initialized,
      clearCart,
      updateItems,
      updateCheckoutNote,
      vacation,
    }
}

export const CartContext = React.createContext({
  addItem: () => {},
  checkoutId: '',
  itemCount: 0,
  fetchCheckout: () => {},
  createCheckout: () => {},
  removeItem: () => {},
  checkout: null,
  initialized: false,
  clearCart: () => {},
  vacation: null,
})

