import {GraphQLClient} from 'graphql-request'
import Client from 'shopify-buy';


export const client = new GraphQLClient('https://cooperationcoffee.myshopify.com/api/2025-01/graphql.json', {
    headers: {
        'X-Shopify-Storefront-Access-Token': '7fd760a395ddbb756bf99e86ad2ae57e'
    }
})

export const shopify = Client.buildClient({
    domain: 'cooperationcoffee.myshopify.com',
    storefrontAccessToken: '7fd760a395ddbb756bf99e86ad2ae57e'
});
