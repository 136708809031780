class LineItem {
    public  id: string;
    public  variantId: string;
    public  title: string;
    public  engName: string;
    public  spec: string;
    public  url: string;
    public  imageUrl: string;
    public  productType: string;
    public  count: number;
    public  price: number;
    public  customAttributes: any[];
    constructor(
       data: Partial<LineItem>
    ) {
        Object.assign(this, data);
    }
    static fromShopify({ node }): LineItem {

        const metafields = (node?.merchandise.product?.metafields ?? []).reduce((acc, node) => {
          if (!node) return acc;
          if (node.key === '_long_description') {
            acc.detailText = node.value;
          } else if (node.key === 'eng_name') {
            acc.engName = node.value;
          }
          return acc;
        }, {});

        const productType = node.merchandise.product.productType;

        const getSpec = () => {
          if (productType === '咖啡豆') {
            const s = node.merchandise.title.replace('單賣 /', '');
            if (s.includes('包')) {
              return `掛耳 ${s}`;
            }
            return s;
          }

          return node.merchandise.title;
        }
        const spec = getSpec();

        return new LineItem({
            id: node.id,
            variantId: node.merchandise.id,
            title: node.merchandise.product.title,
            spec,
            url: `/products/${node.merchandise.product.handle}`,
            productType: node.merchandise.product.productType,
            price: node.quantity * parseInt(node.merchandise.priceV2.amount || 0),
            imageUrl: node.merchandise.image.url,
            customAttributes: node.attributes,
            count: node.quantity,
            ...metafields,
        })
    }
}

export class Checkout {
    public id: string;
    public webUrl: string;
    public address1: string;
    public address2: string;
    public company: string;
    public email: string;
    public firstName: string;
    public lastName: string;
    public phone: string;
    public note: string;
    public items?: LineItem[];
    public price: number;
    public subtotalPrice: number;
    public paymentDue: number;
    public availableShippingRates: any[];


    constructor(data : any = {}) {
        Object.assign(this, data);
    }

    static fromShopify({ node }): Checkout {
        const items = (node?.lines?.edges ?? [])
          .map((edge) =>
            LineItem.fromShopify(edge)
          )
        return new Checkout({
            id: node.id,
            items,
            webUrl: node.webUrl,
            note: node.note,
            price: parseInt(node.cost.totalAmount.amount || 0),
            subtotalPrice: parseInt(node.cost.subtotalAmount.amount || 0),
            address1: node.shippingAddress?.address1,
            address2: node.shippingAddress?.address2,
            city: node.shippingAddress?.city,
            phone: node.shippingAddress?.phone,
            firstName: node.shippingAddress?.firstName,
            lastName: node.shippingAddress?.lastName,
            email: node.email,
            availableShippingRates: (node.deliveryGroups?.nodes[0]?.deliveryOptions),
            deliveryAddress: node.delivery?.addresses[0],
            shippingDiscount: node.discountAllocations.filter(v => v.discountApplication.targetType === 'SHIPPING_LINE').reduce((r, v) => {
              return r + parseFloat(v.discountedAmount.amount)
            }, 0),
            deliveryGroup: node.deliveryGroups?.nodes[0],
            ...(
              node.paymentDue?.amount && {
                paymentDue: 
                  parseInt(node.paymentDue?.amount),
              }
           )
        });
    }
}
